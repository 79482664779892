import React from "react";
import Menu from "../../common/Menu/Menu";
import Accordion from "./Accordion";

const privacy = () => {
  return (
    <>
      <Menu header_style_2={true} />
      <Accordion />
    </>
  );
};

export default privacy;
