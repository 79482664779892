import React from "react";
import Footer from "../../common/Footer/Footer";
import Menu from "../../common/Menu/Menu";
import ServiceAbout from "./ServiceAbout";
import ServiceDetailsAbout from "../ServiceDetails/ServiceDetailsAbout";
import TopFooter from "../../common/TopFooter/TopFooter";
import FullScreenBtn from "../../common/FullScreenBtn";

const Service = () => {
  return (
    <>
      <Menu header_style_2={true} />
      <ServiceAbout />
      <ServiceDetailsAbout />
      <FullScreenBtn />
      <TopFooter  />
      <Footer />
    </>
  );
};

export default Service;
