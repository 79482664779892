import React from "react";
import Menu from "../../common/Menu/Menu";
import AboutCompany from "./AboutCompany";
import Footer from "../../common/Footer/Footer";
import TopFooter from "../../common/TopFooter/TopFooter";
import FullScreenBtn from "../../common/FullScreenBtn";

const About = () => {
  return (
    <>
      <Menu header_style_2={true} />
      <AboutCompany />
      <FullScreenBtn />
      <TopFooter />
      <Footer />
    </>
  );
};

export default About;
