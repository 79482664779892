import React from "react";

const FullScreenBtn3 = () => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <>
      {/* <!-- full screen btn area  --> */}
      <div className="tp-theme-btn-area pt-90 pb-140">
        <div className="container">
          <div className="row justify-content-center p-relative">
            <div className="col-lg-12 d-flex justify-content-center">
              <a 
                href="https://form.scorpioncapital.co.uk" 
                target="_blank" 
                rel="noopener noreferrer"
                onClick={scrollToTop}
                style={{ marginBottom: '0px' }} // Example of reducing space
              >
                <div className="tp-theme-btn d-flex align-items-center white-bg">
                  <div className="tp-btn-inner">
                    <p className="mb-0 text-white"></p>
                    <b>Quick Contact</b>
                    <span className="d-inline-block">
                      <svg
                        width="46"
                        height="8"
                        viewBox="0 0 46 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M45.3536 4.35355C45.5488 4.15829 45.5488 3.84171 45.3536 3.64645L42.1716 0.464466C41.9763 0.269204 41.6597 0.269204 41.4645 0.464466C41.2692 0.659728 41.2692 0.976311 41.4645 1.17157L44.2929 4L41.4645 6.82843C41.2692 7.02369 41.2692 7.34027 41.4645 7.53553C41.6597 7.7308 41.9763 7.7308 42.1716 7.53553L45.3536 4.35355ZM0 4.5H45V3.5H0V4.5Z"
                          fill="currentColor"
                        />
                      </svg>
                      <svg
                        width="46"
                        height="8"
                        viewBox="0 0 46 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M45.3536 4.35355C45.5488 4.15829 45.5488 3.84171 45.3536 3.64645L42.1716 0.464466C41.9763 0.269204 41.6597 0.269204 41.4645 0.464466C41.2692 0.659728 41.2692 0.976311 41.4645 1.17157L44.2929 4L41.4645 6.82843C41.2692 7.02369 41.2692 7.34027 41.4645 7.53553C41.6597 7.7308 41.9763 7.7308 42.1716 7.53553L45.3536 4.35355ZM0 4.5H45V3.5H0V4.5Z"
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                  </div>
                </div>
              </a>
            </div>
            <div className="tp-hr-border"></div>
          </div>
        </div>
      </div>
      {/* <!-- full screen btn area end  --> */}
    </>
  );
};

export default FullScreenBtn3;
