import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const TopAbout = () => {
  useEffect(() => {
    // IntersectionObserver logic
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('show');
          observer.unobserve(entry.target); // Stop observing once it's visible
        }
      });
    });

    // Selecting all elements with the fade-in class
    const fadeElements = document.querySelectorAll('.fade-in');
    fadeElements.forEach((element) => observer.observe(element));

    // Clean up observer on unmount
    return () => observer.disconnect();
  }, []);

  return (
    <>
      {/* <!-- about area start --> */}
      <div className="tp-about-area pt-140">
        <div className="container">
          <div className="row justify-content-end">
            <div className="col-lg-6">
              <div className="tp-about-left pr-90">
                <div className="tp-section-wrapper pt-5">
                  <span className="tp-section-subtitle fade-in">
                   
                  </span>
                  <h2 className="tp-section-title pt-25 mb-30 fade-in">
                  Finance in the right hands.
                  </h2>
                  <p className="fade-in"> {/* Add fade-in here */}
                  We offer comprehensive support across the full spectrum of debt and equity with the experience and knowledge to navigate the most complex funding requirements.
                  </p>
                  <p className="pt-10 fade-in"> {/* Add fade-in here */}
                  We are comfortable working with all forms of corporate entity in addition to trusts, funds, international clients and structures. <br></br><br></br><br></br>
                  </p>
                </div>
                <div className="mt-45 mb-30 fade-in"> {/* Add fade-in here */}
                  <Link to="/contact">
                    <div className="tp-theme-btn d-flex align-items-center">
                      <div className="tp-btn-inner">
                        <p className="mb-0"></p>
                        <b>Talk to Us</b>
                        <span className="d-inline-block">
                          <svg
                            width="46"
                            height="8"
                            viewBox="0 0 46 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M45.3536 4.35355C45.5488 4.15829 45.5488 3.84171 45.3536 3.64645L42.1716 0.464466C41.9763 0.269204 41.6597 0.269204 41.4645 0.464466C41.2692 0.659728 41.2692 0.976311 41.4645 1.17157L44.2929 4L41.4645 6.82843C41.2692 7.02369 41.2692 7.34027 41.4645 7.53553C41.6597 7.7308 41.9763 7.7308 42.1716 7.53553L45.3536 4.35355ZM0 4.5H45V3.5H0V4.5Z"
                              fill="currentColor"
                            />
                          </svg>
                          <svg
                            width="46"
                            height="8"
                            viewBox="0 0 46 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M45.3536 4.35355C45.5488 4.15829 45.5488 3.84171 45.3536 3.64645L42.1716 0.464466C41.9763 0.269204 41.6597 0.269204 41.4645 0.464466C41.2692 0.659728 41.2692 0.976311 41.4645 1.17157L44.2929 4L41.4645 6.82843C41.2692 7.02369 41.2692 7.34027 41.4645 7.53553C41.6597 7.7308 41.9763 7.7308 42.1716 7.53553L45.3536 4.35355ZM0 4.5H45V3.5H0V4.5Z"
                              fill="currentColor"
                            />
                          </svg>
                        </span>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="tp-about-right p-relative">
                <div className="about-img text-md-end fade-in"> {/* Add fade-in here */}
                  <img src="assets/img/about/about.png" alt="A city skyline in the daytime, observing upwards from street level towards two large skyscraper buildings" />
                </div>
                <div className="tp-about-info text-center grey-bg-2 fade-in"> {/* Add fade-in here */}
                <h4>Over</h4>
                  <h3>50</h3><br/>
                  <h4>
                    Years of <br />
                    Experience
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- about about end--> */}
    </>
  );
};

export default TopAbout;
