import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);

    // Set a timeout to click the scroll-to-top button after 0.5 seconds
    const timer = setTimeout(() => {
      const scrollToTopButton = document.querySelector("#scroll-to-top-btn");
      if (scrollToTopButton) {
        scrollToTopButton.click();
      }
    }, 500); // 0.5 seconds delay

    // Clean up the timeout if the component unmounts before the timeout completes
    return () => clearTimeout(timer);
  }, [pathname]); // Run effect when pathname changes

  return null;
};

export default ScrollTop;
