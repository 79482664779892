import React, { useEffect } from "react";

const ServiceDetailsAbout = () => {
  useEffect(() => {
    // IntersectionObserver setup
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('show');
          observer.unobserve(entry.target); // Stop observing once it's visible
        }
      });
    });

    // Select all elements with the fade-in class
    const fadeElements = document.querySelectorAll('.fade-in');
    fadeElements.forEach((element) => observer.observe(element));

    // Clean up observer on unmount
    return () => observer.disconnect();
  }, []);

  return (
    <>
      <div className="tp-service-page pt-50 pb-50">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="tp-sv__content">
                <h3 className="tp-sv__title fade-in">Partner with <b>Scorpion Capital </b></h3>
                <p className="fade-in">
                Our team have secured over a billion in Funding and venture partnerships, for both government and private sector projects working alongside family offices, private equity, institutional lenders and international funds.
                </p>
                <p className="fade-in">
                A partnership with Scorpion Capital offers not just enhanced financial solutions for your clients but access to our network so they can craft more robust financial strategies, ensuring they are well-positioned to capitalise on market opportunities for their clients they otherwise would not have the opportunity to access.

                </p>
                <p className="fade-in">
                To ensure any borrowing proposition has the best chance of success we have a simple checklist:

                </p>
                <p className="fade-in">
                1. Good asset and strong borrower profile.<br></br>
                2. Clear narrative and explanation of the transaction from entry to exit.
                </p>
                <p className="fade-in">
                To present a deal, please download the form using the clickable button below and send to letstalk@scorpioncapital.co.uk
                </p>
              </div>
            </div>
          </div>
          <div className="row pb-30">
            <div className="col-lg-6 mb-30">
              <div className="tp-sv-inner-img w-img fade-in">
                <img src="/assets/img/service/service-blog-2.jpg" alt="skyline from a distance" />
              </div>
            </div>
            <div className="col-lg-6 mb-30">
              <div className="tp-sv-inner-img w-img fade-in">
                <img src="/assets/img/service/service-blog-1.png" alt="skyline from a distance - London" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceDetailsAbout;
