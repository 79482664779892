import React, { useEffect } from "react";

const ServiceDetailsAbout = () => {
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('show');
          observer.unobserve(entry.target); // Stop observing once it's visible
        }
      });
    });

    const fadeElements = document.querySelectorAll('.fade-in');
    fadeElements.forEach((element) => observer.observe(element));

    return () => observer.disconnect();
  }, []);

  return (
    <>
      <div className="tp-service-page pt-20 pb-20">
        <div className="container">
          <div className="row pb-30">
            <div className="col-lg-6 mb-30">
              <div className="tp-sv-inner-img w-img fade-in"></div>
            </div>
            <div className="col-lg-6 mb-30">
              <div className="tp-sv-inner-img w-img fade-in"></div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="tp-sv__content">
                <h3 id="bridging-loans" className="tp-sv__subtitle fade-in">Bridging Loans</h3>
                <p className="fade-in">
                Bridging loans are short-term loans designed to provide immediate financing, bridging the gap between the need for quick capital and the availability of longer-term funding. We specialise in offering swift and flexible solutions through our extensive network of alternative lenders, ideal for seizing time-sensitive opportunities, covering urgent financial needs, or securing properties before permanent financing is arranged. 
                </p>
                <h4 id="commercial-mortgages" className="tp-sv__subtitle fade-in">Commercial Mortgages</h4>
                <p className="fade-in">
                Commercial mortgages are loans used to purchase or refinance commercial properties. Scorpion Capital offers comprehensive commercial mortgage solutions, providing competitive rates and flexible terms to assist across all forms of asset class.
                </p>
                <h4 id="term-loans" className="tp-sv__subtitle fade-in">Term Loans</h4>
                <p className="fade-in">
                Term loans provide fixed or variable-rate financing over a set period for various business needs, we will structure your product to meet the specific financial requirements of the business, offering predictable payment schedules and competitive rates for long-term capital needs.
                </p>
                <h4 id="mezzanine-lending" className="tp-sv__subtitle fade-in">Mezzanine Lending</h4>
                <p className="fade-in">
                Mezzanine lending involves providing capital that sits between senior debt and equity in the capital structure, typically used to fund growth, acquisitions, or buyouts. Scorpion Capital’s mezzanine lending providers offer our clients access to growth capital with the flexibility of bespoke terms, catering to unique financial needs while bridging the gap between senior debt and equity.
                </p>
                <h4 id="development-finance" className="tp-sv__subtitle fade-in">Development Finance</h4>
                <p className="fade-in">
                Development finance supports property developers in funding new projects or property renovations. From acquiring land and covering construction costs to navigating the complexities of larger-scale developments, we offer flexible finance packages tailored to your specific project needs. With deep relationships across local and international banks, private lenders, and institutional investors, we’re well-positioned to secure competitive rates and terms, guiding your project from conception to completion.
                </p>
                <h4 id="development-exit-finance" className="tp-sv__subtitle fade-in">Development Exit Finance</h4>
                <p className="fade-in">
                Development exit finance is designed to help developers refinance or exit from a development project upon completion. Scorpion Capital offers specialised exit finance solutions, enabling developers to transition. Smoothly from development to long-term financing or sale, ensuring financial stability and maximising returns.
                </p>
                <h4 id="investment-finance" className="tp-sv__subtitle fade-in">Investment Finance</h4>
                <p className="fade-in">
                Investment finance supports businesses and individuals in acquiring assets or funding ventures that promise significant returns. Scorpion Capital provides investment finance with a focus on strategic alignment and high-growth potential, delivering tailored solutions to help clients capitalise on lucrative investment opportunities.
                </p>
                <h4 id="portfolio-restructuring" className="tp-sv__subtitle fade-in">Portfolio Restructuring & Formation</h4>
                <p className="fade-in">
                Portfolio restructuring involves reorganising a portfolio of assets to improve performance or address financial challenges, we offer expert advice and strategic solutions to optimise asset allocation, enhance returns, and manage risk effectively.
                </p>
                <h4 id="auction-finance" className="tp-sv__subtitle fade-in">Auction Finance</h4>
                <p className="fade-in">
                 Auction Finance is a form of bridging loan used to buy a property at auction and complete the purchase quickly.
Auction purchases generally have to be completed within 28 days of the auction. In general, if you want to buy a property the conventional way, it is unlikely to be arranged within that period.
The auction house will usually ask you to pay 10% of the purchase price. In addition, auction fees will be payable in the case of a successful bid.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceDetailsAbout;







               


                

                
                

                
                

                
                

                
                

                
               

                
                

                
                

