import React from "react";
import Menu from "../../common/Menu/Menu";
import ContactInfo from "./ContactInfo";
import ScrollTop from "../../common/ScrollTop"; // Ensure ScrollTop is imported

const ContactUs = () => {
  return (
    <>
      <ScrollTop /> {/* Ensure ScrollTop is included */}
      <Menu header_style_2={true} />
      <div className="main-content">
        <ContactInfo />
      </div>
    </>
  );
};

export default ContactUs;
