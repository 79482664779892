const menu_data = [
  {
    id: 1,
    title: 'Home',
    link: '/',
  },
  {
    id: 2,
    title: 'About',
    link: '/about',
  },
  {
    id: 3,
    title: 'Services',
    link: '/service',
  },
  {
    id: 4,
    title: 'Partnerships',
    link: '/partnerships',
  },
  {
    id: 5,
    title: 'Contact',
    link: '/contact',
  },
]

export default menu_data;
