import React from "react";
import Footer from "../../common/Footer/Footer";
import Menu from "../../common/Menu/Menu";
import PartnershipsItems from "./PartnershipsItems";
import TopFooter from "../../common/TopFooter/TopFooter";
import FullScreenBtn2 from "../../common/FullScreenBtn2";
import FullScreenBtn3 from "../../common/FullScreenBtn3";

const Partnerships = () => {
  return (
    <>
      <Menu header_style_2={true} />
      <PartnershipsItems />
      <FullScreenBtn3 />
      <FullScreenBtn2 />
      <TopFooter />
      <Footer />
    </>
  );
};

export default Partnerships;
