import React from "react";

const TopFooter = () => {
  return (
    <>
      <div className="tp-footer__area black-bg">
        <div className="tp-footer">
          {/* <!-- footer top --> */}
          <div className="tp-footer__top pt-90 pb-60">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-5">
                  <h3 className="tp-footer__top-title">
                    Have a project in mind? Let’s work together.
                  </h3>
                </div>
                <div className="col-lg-7">
                  <div className="tp-insta d-flex justify-content-lg-end">
                    <div className="tp-insta__item p-relative ml-20">
                      {/* Single image replacing three images */}
                      <img
                        src="assets/img/footer/footer-sm-1.jpg"
                        alt="city skyscrapers"
                        className="landscape-img"
                      />
                      {/* Email icon that appears on hover */}
                      <a
                        href="mailto:letstalk@scorpioncapital.co.uk" // Replace with your actual email
                        className="email-icon"
                      >
                        <i className="fa fa-envelope"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- footer top end  --> */}
        </div>
      </div>

      {/* Add styling for hover effect */}
      <style jsx>{`
        .email-icon {
          display: none;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 2rem;
          color: white;
        }

        .tp-insta__item:hover .email-icon {
          display: block;
        }

        .tp-insta__item {
          position: relative;
        }
      `}</style>
    </>
  );
};

export default TopFooter;
