import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      {/* <!-- footer area start --> */}
      <footer>
        <div className="tp-footer__area black-bg ">
          <div className="tp-footer ">
            {/* <!-- main-footer start  --> */}
            <div className="tp-footer__main">
              <div className="container">
                <div className="tp-footer-border pt-60 pb-30">
                  <div className="row">
                    <div className="col-lg-3 col-md-6">
                      <div className="tp-footer__widget  pb-30">
                        <h3 className="tp-footer__widget-title">
                          Locations
                        </h3>
                        <ul>
                          <li>
                            London
                          </li>
                          <li>
                            Manchester
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <div className="tp-footer__widget pb-30">
                        <h3 className="tp-footer__widget-title">Services</h3>
                        <ul>
                          <li>
                          Bridging Loans
                          </li>
                          <li>
                          Mezzanine Lending
                          </li>
                          <li>
                          Development Finance
                          </li>
                          <li>
                          Development Exit Finance
                          </li>
                          <li>
                          Commercial Mortgages
                          </li>
                          <li>
                          Term Loans
                          </li>
                          <li>
                          Portfolio Restructuring
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 ">
                      <div className="tp-footer__widget  pb-30">
                        <h3 className="tp-footer__widget-title">Information</h3>
                        <ul>
                          <li>
                            <Link to="/privacy">Privacy Policy</Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <div className="tp-footer__widget  pb-30">
                        <h3 className="tp-footer__widget-title">Contact</h3>
                        <ul>
                          <li>
                            <p>
                            Scorpion Capital,<br></br>Capital House,<br></br>272 Manchester Road,<br></br>Greater Manchester,<br></br>M43 6PW<br></br><br></br>
                            </p>
                          </li>
                          <li>
                          {/* <!-- add phone number -->  <a href=""></a> */}
                          </li><br></br>
                          <li>
                            <a href="mailto:letstalk@scorpioncapital.co.uk">
                            letstalk@scorpioncapital.co.uk
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- main footer end  --> */}
          </div>
        </div>

        {/* <!-- footer copy right --> */}
        <div className="top-footer-copyright pt-30 black-bg">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-12">
                <div className="tp-copyrigh-text mb-30">
                  <span>
                    © {new Date().getFullYear()} Scorpion Capital All Rights Reserved.
                  </span>
                </div>
              </div>

                    {/* Social Icons
              <div className="col-md-4 col-12">
                <div className="tp-footer-social-icon mb-30 text-md-end">
                  <ul>
                    <li>
                      <a href="/">
                        <i className="fab fa-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a href="/">
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="/">
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>
                    <li>
                      <a href="/">
                        <i className="fab fa-youtube"></i>
                      </a>
                    </li>
                    <li>
                      <a href="/">
                        <i className="fab fa-linkedin"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div> */}

            </div>
          </div>
        </div>
        {/* <!-- footer copyright end  --> */}
      </footer>
      {/* <!-- footer area end --> */}
    </>
  );
};

export default Footer;
